
<template>
  <section :id="id" class="location_la push-v--desktop--large push-v--tablet--large push-v--mobile--x-small" :class="{'for-home':isForHome}">
    <div ref="bg" class="location_la--green-bg" :class="{'for-home':isForHome}">
      <div class="location_la--green-bg__container" :class="{'for-home':isForHome}">
        <div class="wrapper">
          <div v-show="!dragging" class="bg"><span class="bar" :style="`left: ${scrollX}`" /></div>
          <div ref="arrowBox" class="arrow" @mouseenter="onArrowhover">
            <div :style="[scrollX == '96%'? {'visibility': `hidden`} : {'visibility': `visible`}]" class="arrow--icon" />
          </div>
        </div>
        <div v-if="isForHome" class="carousel-info carousel mobile" :class="{'for-home':isForHome}">
          <span class="carousel-info--subtitle">{{ subtitle }}</span>
          <h2 class="carousel-info--title push-v--desktop--small push-v--tablet--x-small push-v--mobile--x-small">
            {{ title }}
          </h2>
          <div class="bar" />
          <p class="carousel-info--desc push-v--desktop--small push-v--tablet--x-small push-v--mobile--x-small">
            {{ description }}
          </p>
        </div>
      </div>
    </div>
    <div id="slider" v-click-outside="stopDrag" class="content" :class="{'for-home':isForHome}" @mousemove="mouseMoving">
      <div
          v-if="images.length"
          id="slider-cards"
          ref="sliderCards"
          class="carousel-wrapper horizontal-scroll-wrapper squares"
          :style="`transform: translate3d(${cardsX}px,0,0)`"
          @mousedown="startDrag"
          @mouseup="stopDrag"
          @mouseleave="stopDrag"
      >
        <div class="carousel-info carousel" :class="{'for-home':isForHome}">
          <span class="carousel-info--subtitle">{{ subtitle }}</span>
          <h2 class="carousel-info--title push-v--desktop--small push-v--tablet--x-small push-v--mobile--x-small">
            {{ title }}
          </h2>
          <div class="bar" />
          <p class="carousel-info--desc push-v--desktop--small push-v--tablet--x-small push-v--mobile--x-small">
            {{ description }}
          </p>
        </div>
        <div
            v-for="(image, index) in images"
            :key="`image-i-` + index"            
            :class="[`img-container--${index}`, 'carousel--content', image.contentCodename]"
            :style="[(parallexIndices.includes(index))? {'transform': `translate3d(${0.15*paraX}px,0,0)`} : {'transform': 'none'}]"
            @mouseenter="onHover"
            @mouseleave="onHoverLeave"
        >
          <span class="carousel--caption">{{ image.caption }}</span>
          <div :class="[`image--${index}`, 'carousel']">
            <KenticoImage 
                class="carousel--image"
                :src="image.image.url"
                draggable="false"
                :sizes="imageSizes"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from 'vue';
// import { gsap, TimelineLite, CSSPlugin, Power1 as Sine } from 'gsap';
// import { gsap, CSSPlugin } from 'gsap';


export default Vue.extend({
  props: {
    id: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    description: { type: String, default: '' },
    isForHome: { type: Boolean, default: false },
    images: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      orderedImages: [],
      selectedIndex: 0,
      dragging: false,
      initialMouseX: 0,
      initialCardsX: 0,
      cardsX: 0,
      parallexIndices: [2, 4],
      tl: false,
      arrowTl: {},
      scrollX: 0,
      paraX: 0,
      lastCardsx: 0,
      imageSizes: {
        default: [900, 750],     
      },

    };
  },
  computed: {
    selectedSlide() {
      return this.orderedImages[this.selectedIndex];
    },
    totalWidth() {
      return this.$refs.sliderCards ? this.$refs.sliderCards.clientWidth - window.innerWidth : 1000;
    },
    
  },
  mounted() {
    
    /**
     *  SHAMEFUL CODE START
     *  Sometime you have to do things you're not proud of , this is one of them :(
     */
    const bgNodes = document.getElementsByClassName('location_la--green-bg');
    if (bgNodes.length == 2) {
      // remove duplicated nodes
      bgNodes[0].style.display='none';
    }
    /**
     * SHAMEFUL CODE END
     */
    // gsap.registerPlugin(CSSPlugin);
    
    /**
     this.$props.images.sort((a, b) => {
      const first = Number(a.contentCodename.match(/(\d+)/)[0]);
      const second = Number(b.contentCodename.match(/(\d+)/)[0]);
      // a is less than b by some ordering criterion
      if (first < second) {
        return -1;
      }
      // a is greater than b by the ordering criterion
      if (first > second) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    */
    this.scrollListener = (e) => this.onScroll(e);
    this.blurListener = (e) => this.stopDrag(e);

    document.querySelector('#slider').addEventListener('scroll', this.scrollListener );
    this.$el.addEventListener('blur', this.blurListener );
  },
  beforeDestroy() {
    document.querySelector('#slider') && document.querySelector('#slider').removeEventListener('scroll', this.scrollListener);
    this.$el.removeEventListener('blur', this.blurListener );
  },
  methods: {
    startDrag(e) {
      this.dragging = true;
      this.initialMouseX = e.pageX || (e.touches && e.touches[0].clientX);
      this.initialCardsX = this.cardsX;
      this.initialPhtotX=this.photoX;
      this.lastCardsx = this.cardsX;
    },
    onScroll(e) {
      const el = e.target;
      const left = el.scrollLeft;
      const scrollWidth = (Math.abs(left)/this.totalWidth)*100;
      this.scrollX=scrollWidth > 96 ? '96%':`${scrollWidth}%`;
      this.paraX = -left;

    },
    stopDrag() {
      this.dragging = false;
      if (this.cardsX >= 20) {
        this.cardsX = 0;
      } else if (this.cardsX <= -this.totalWidth) {
        this.cardsX = -this.totalWidth;
      }
      this.paraX = this.cardsX;

      const scrollWidth = (Math.abs(this.cardsX)/this.totalWidth)*100;
      this.scrollX=scrollWidth > 96 ? '96%':`${scrollWidth}%`;
      
      
    },
    resetPhotoX() {
      // this.photoX = 0;      
    },
    onHover(e) {
      e.stopImmediatePropagation();
    },
    onArrowhover() {
      // const { arrowBox } = this.$refs;
      // this.arrowTl = new TimelineLite();
      // this.arrowTl.fromTo(arrowBox, { x: 0 }, { x: 30, repeat: 1, yoyo: true, paused: true, duration: 0.26, ease: Sine.easeInOut }, '+=0.1');
      
    },
    onHoverLeave(e) {
      
    },
    mouseMoving(e) {
      if (this.dragging) {
        const thisMouseX = e.pageX || (e.touches && e.touches[0].clientX);
        const dragAmount = thisMouseX - this.initialMouseX;
        const targetX = this.initialCardsX + dragAmount;
        this.cardsX = isNaN(targetX) ? this.lastCardsx : targetX;// Math.max(-this.totalWidth, targetX);// Number.isNaN(targetX) ? this.totalWidth : targetX;
        this.paraX = this.cardsX;
        this.lastCardsx = this.cardsX;
      }
    },
  },
});
</script>
<style lang="scss">
@import '~/assets/scss/atomic/modules/_location_look_around.scss';
</style>
